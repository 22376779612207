import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Alert, CircularProgress, Snackbar} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await axios.post('https://vitalclic-production.up.railway.app/api/login', formData);
      localStorage.setItem('token', response.data.token);
      navigate("/");
    } catch (err) {
      console.log(err.response.status);
      if (err.response && err.response.status === 403) {
        setError("El usuario está en pendiente")
      } else if (err.response && err.response.status === 400) {
        setError("Usuario no encontrado")

      } else {
        setError("Ocurrió un error inesperado")

      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 5,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
      }}
    >
      <Box
        component="img"
        src={require("../../assets/Images/logo.png")}
        alt="Logo"
        sx={{
          width: 200,
          mx: 'auto',
          mb: 2,
          backgroundColor: "#53676c",
          p: 2,
          marginTop: "3em",
          borderRadius: "5px"

        }}
      />
      <Typography variant="h5" align="center" gutterBottom>
        Iniciar Sesión
      </Typography>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <TextField
        label="Correo Electrónico"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
        fullWidth
        sx={{
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "#53676c", // Cambia el color del borde al pasar el mouse
              },
              "&.Mui-focused fieldset": {
                borderColor: "#53676c", // Cambia el color del borde cuando el campo está enfocado
              },
            },
            "& .MuiInputLabel-root": {
              color: "#000", // Color del label por defecto
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#53676c", // Cambia el color del label cuando está enfocado
            },
          }}
      />
      <TextField
        label="Contraseña"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        required
        fullWidth
        sx={{
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "#53676c", // Cambia el color del borde al pasar el mouse
              },
              "&.Mui-focused fieldset": {
                borderColor: "#53676c", // Cambia el color del borde cuando el campo está enfocado
              },
            },
            "& .MuiInputLabel-root": {
              color: "#000", // Color del label por defecto
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#53676c", // Cambia el color del label cuando está enfocado
            },
          }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isLoading}
        sx={{backgroundColor: "#53676c", ":hover":{backgroundColor: "#69848BFF"}}}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Ingresar'}
      </Button>
    </Box>
  );
};

export default Login;
