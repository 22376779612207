import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // Importa la localización en español
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/Home/DatePickerComponent.css'; // Estilos personalizados

// Registra la localización en español
registerLocale('es', es);

const DatePickerComponent = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (<div>
   
  
    <div id='date' className="date-picker-container">
      <h2>SELECCIONAR FECHA</h2>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat="dd 'de' MMMM 'de' yyyy"
        locale="es" // Aplica la localización en español
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        inline
        className="calendar"
      />
    </div>
  <div className='hr'></div>

    </div>

    
  );
};

export default DatePickerComponent;
