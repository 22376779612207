import React from "react";
import styles from "../../styles/Home/Sede.module.css";
import { useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};
const Sede = () => {
  const locations = [
    {
      name: "NOMBRE 1",
      description:
        "Lorem ipsum dolor sit amet, adipiscing elit. Sed a fringilla.",
      image: "https://via.placeholder.com/100",
      department: "Lima",
      province: "Lima",
      district: "Miraflores",
    },
    {
      name: "NOMBRE 2",
      description:
        "Lorem ipsum dolor sit amet, adipiscing elit. Sed a fringilla.",
      image: "https://via.placeholder.com/100",
      department: "Lima",
      province: "Lima",
      district: "San Isidro",
    },
    {
      name: "NOMBRE 3",
      description:
        "Lorem ipsum dolor sit amet, adipiscing elit. Sed a fringilla.",
      image: "https://via.placeholder.com/100",
      department: "Arequipa",
      province: "Arequipa",
      district: "Arequipa",
    },
    {
      name: "NOMBRE 4",
      description:
        "Lorem ipsum dolor sit amet, adipiscing elit. Sed a fringilla.",
      image: "https://via.placeholder.com/100",
      department: "Arequipa",
      province: "Islay",
      district: "Mollendo",
    },
  ];
  const [showLocations, setShowLocations] = React.useState(false); // Nuevo estado para controlar la visibilidad
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div id="sede" className={styles.container_sede}>
      <>
        <h1>SELECCIONAR SEDE</h1>
        <div className={styles.hr}></div>
        <div className={styles.locations_list}>
          {locations &&
            locations.map((location, index) => (
              <div key={index} className={styles.location_card}>
                <img
                  src={require("../../assets/Images/VITAL CLIC IMAGEN2.png")}
                  alt="Sede"
                  className={styles.location_image}
                />
                <div className={styles.location_info}>
                  <h2>{location.name}</h2>
                  <p>{location.description}</p>
                  <span className={styles.link} onClick={handleOpen}>
                    Ver Más información
                  </span>
                  <br />
                  <span className={styles.link}>Seleccionar</span>
                </div>
              </div>
            ))}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className={styles.profile}>
                  <img
                    src="https://via.placeholder.com/150"
                    alt="Profile"
                    className={styles.profileImage}
                  />
                  <h1 className={styles.name}>John Doe</h1>
                  <p className={styles.description}>
                    Frontend Developer passionate about creating interactive
                    applications and experiences on the web.
                  </p>
                  <div className={styles.address}>
                    <p>1234 React Street</p>
                    <p>San Francisco, CA 94105</p>
                  </div>
                  <div className={styles.socialLinks}>
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialLink}
                    >
                      Facebook
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialLink}
                    >
                      Twitter
                    </a>
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialLink}
                    >
                      LinkedIn
                    </a>
                  </div>

                  
                </div>
             
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default Sede;
