import React, { useState } from 'react';
import Form from './Form'
import Sede from './Sede';
import DatePickerComponent from './DatePickerComponent';
import TimeSelector from './TimeSelector';
import PatientForm from './PatientForm';
import ServiceAditional from './ServiceAditional';
import AditionalForm from './AditionalForm';





export default function Home() {

  return (
    <div>
   <Form/>
   <Sede/>
   <DatePickerComponent/>
   <TimeSelector/>
   <PatientForm/>
   <ServiceAditional/>
   <AditionalForm/>
    </div>
  );
}
