// TimeSelector.js
import React, { useState } from 'react';
import styles from '../../styles/Home/TimeSelector.module.css';

const TimeSelector = () => {
    const [selectedTime, setSelectedTime] = useState(null);
    
    const times = Array.from({ length: 24 }, (_, i) => i);

    const handleClick = (time) => {
        setSelectedTime(time);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>SELECCIONAR HORA</h2>
            <div className={styles.grid}>
                {times.map((time) => (
                    <div
                        key={time}
                        className={`${styles.time} ${selectedTime === time ? styles.selected : ''}`}
                        onClick={() => handleClick(time)}
                    >
                        {`${time}:00`}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TimeSelector;
